import React from 'react';
import VariationModel from '../KexVariation/Models/VariationModel.interface';
import { styled } from '@glitz/react';
import { theme } from '../Theme';
import ShoppingBasketIconBold from '../Shared/Icons/ShoppingBasketIconBold';
import { useAppSettingsData } from '../Shared/AppSettingsProvider/AppSettingsProvider';
import CheckoutProductCard from './CheckoutProductCard';
import CheckoutMobileProductCard from './CheckoutMobileProductCard';
import KexCart from '../Cart/Models/KexCart.interface';
import { EmptyCart, GetCart } from '../Cart/Cart';
import { media, pseudo } from '@glitz/core';
import useMedia from '../Shared/Hooks/useMedia';
import TrashIcon from '../Shared/Icons/TrashIcon';

// type PropTypes = {
//   cart: KexCart;
//   isLoading: boolean;
//   // lineItems: VariationModel[];
// };

function ProductListing() {
  const {
    translations: {
      'checkoutPage/yourCart': yourCartLabel,
      'common/price': priceLabel,
      'common/quantity': quantityLabel,
      'common/total': totalLabel,
      'common/emptyCart': emptyCartLabel,
    },
    languageRoute,
  } = useAppSettingsData();
  const { cart, isLoading } = GetCart();
  const isDesktop = useMedia(theme.mediaQuery.mediaMinLarge);

  const onEmptyCart = () => {
    EmptyCart(languageRoute);
  };

  return (
    <Wrapper>
      <TitleWrapper>
        <StyledShoppingBasketIcon />
        <Title>{yourCartLabel}</Title>
      </TitleWrapper>
      {cart && cart.numberOfItems > 0 && (
        <EmptyCartContainer onClick={() => onEmptyCart()}>
          <TextLink>{emptyCartLabel}</TextLink>
          <StyledTrashIcon />
        </EmptyCartContainer>
      )}
      {isDesktop ? (
        <Table>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>{priceLabel}</TableCell>
            <TableCell>{quantityLabel}</TableCell>
            <TableCell>{totalLabel}</TableCell>
          </TableRow>
          {cart &&
            cart.lineItems &&
            cart.lineItems.map((lineItem: VariationModel) => (
              <CheckoutProductCard
                key={lineItem.articleNumber}
                lineItem={lineItem}
                cart={cart}
              />
            ))}
        </Table>
      ) : (
        cart &&
        cart.lineItems &&
        cart.lineItems.map((lineItem: VariationModel) => (
          <CheckoutMobileProductCard
            key={lineItem.articleNumber}
            lineItem={lineItem}
            cart={cart}
          />
        ))
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div({
  backgroundColor: theme.white,
  padding: { x: theme.spacing(4), y: theme.spacing(6) },
  marginBottom: theme.spacing(9),
  borderRadius: '10px',
  ...media(theme.mediaQuery.mediaMinLarge, {
    padding: {
      x: theme.spacing(11),
      top: theme.spacing(8),
      bottom: theme.spacing(15),
    },
  }),
});

const Table = styled.div({
  display: 'table',
  width: '100%',
  borderCollapse: 'collapse',
});

const TableRow = styled.div({
  display: 'table-row',
  borderBottomColor: theme.grayLine,
  borderBottomStyle: 'solid',
  borderBottomWidth: '1px',
  // padding: { y: theme.spacing(3) },
});

const TableCell = styled.div({
  display: 'table-cell',
  textAlign: 'center',
  fontSize: theme.beta,
  padding: { y: theme.spacing(3) },
  ...pseudo(':first-of-type', {
    width: '45%',
  }),
});

const TitleWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(3),
});

const Title = styled.h2({
  fontSize: '22px',
  color: theme.black,
  marginLeft: theme.spacing(3),
});

const StyledShoppingBasketIcon = styled(ShoppingBasketIconBold, {
  fill: theme.black,
});

const EmptyCartContainer = styled.div({
  display: 'flex',
  alignItems: 'baseline',
  justifyContent: 'flex-end',
  marginBottom: '15px',
  paddingRight: '5px',
  ...media(theme.mediaQuery.mediaMinLarge, {
    paddingRight: '15px',
  }),
});

const TextLink = styled.button({
  fontSize: theme.moreLarge,
  color: theme.linkColor,
  marginRight: '6px',
  textDecoration: 'underline',
  ':hover': {
    cursor: 'pointer',
  },
});

const StyledTrashIcon = styled(TrashIcon, {
  stroke: theme.black,
  ':hover': {
    cursor: 'pointer',
  },
});

export default ProductListing;
