import React from 'react';
import { styled, StyledProps } from '@glitz/react';

function IdCardIcon({ compose }: StyledProps) {
  return (
    <styled.Svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      css={compose()}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.25 6.75C7.21447 6.75 6.375 7.58947 6.375 8.625C6.375 9.66053 7.21447 10.5 8.25 10.5C9.28553 10.5 10.125 9.66053 10.125 8.625C10.125 7.58947 9.28553 6.75 8.25 6.75ZM4.875 8.625C4.875 6.76104 6.38604 5.25 8.25 5.25C10.114 5.25 11.625 6.76104 11.625 8.625C11.625 10.489 10.114 12 8.25 12C6.38604 12 4.875 10.489 4.875 8.625Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.25 12.75C7.25544 12.75 6.30161 13.1451 5.59835 13.8484C5.07456 14.3721 4.72173 15.0349 4.57575 15.75H11.9242C11.7783 15.0349 11.4254 14.3721 10.9016 13.8484C10.1984 13.1451 9.24456 12.75 8.25 12.75ZM4.53769 12.7877C5.52226 11.8031 6.85761 11.25 8.25 11.25C9.64239 11.25 10.9777 11.8031 11.9623 12.7877C12.9469 13.7723 13.5 15.1076 13.5 16.5C13.5 16.6989 13.421 16.8897 13.2803 17.0303C13.1397 17.171 12.9489 17.25 12.75 17.25H3.75C3.55109 17.25 3.36032 17.171 3.21967 17.0303C3.07902 16.8897 3 16.6989 3 16.5C3 15.1076 3.55312 13.7723 4.53769 12.7877Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5 9C13.5 8.58579 13.8358 8.25 14.25 8.25H18.75C19.1642 8.25 19.5 8.58579 19.5 9C19.5 9.41421 19.1642 9.75 18.75 9.75H14.25C13.8358 9.75 13.5 9.41421 13.5 9Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5 12C13.5 11.5858 13.8358 11.25 14.25 11.25H20.25C20.6642 11.25 21 11.5858 21 12C21 12.4142 20.6642 12.75 20.25 12.75H14.25C13.8358 12.75 13.5 12.4142 13.5 12Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.65901 2.90901C1.08097 2.48705 1.65326 2.25 2.25 2.25H21.75C22.3467 2.25 22.919 2.48705 23.341 2.90901C23.7629 3.33097 24 3.90326 24 4.5V19.5C24 20.0967 23.7629 20.669 23.341 21.091C22.919 21.5129 22.3467 21.75 21.75 21.75H18.75C18.3358 21.75 18 21.4142 18 21C18 20.8011 17.921 20.6103 17.7803 20.4697C17.6397 20.329 17.4489 20.25 17.25 20.25C17.0511 20.25 16.8603 20.329 16.7197 20.4697C16.579 20.6103 16.5 20.8011 16.5 21C16.5 21.4142 16.1642 21.75 15.75 21.75H8.25C7.83579 21.75 7.5 21.4142 7.5 21C7.5 20.8011 7.42098 20.6103 7.28033 20.4697C7.13968 20.329 6.94891 20.25 6.75 20.25C6.55109 20.25 6.36032 20.329 6.21967 20.4697C6.07902 20.6103 6 20.8011 6 21C6 21.4142 5.66421 21.75 5.25 21.75H2.25C1.65326 21.75 1.08097 21.5129 0.65901 21.091C0.237053 20.669 0 20.0967 0 19.5V4.5C0 3.90326 0.237053 3.33097 0.65901 2.90901ZM2.25 3.75C2.05109 3.75 1.86032 3.82902 1.71967 3.96967C1.57902 4.11032 1.5 4.30109 1.5 4.5V19.5C1.5 19.6989 1.57902 19.8897 1.71967 20.0303C1.86032 20.171 2.05109 20.25 2.25 20.25H4.62868C4.7395 19.9366 4.91946 19.6486 5.15901 19.409C5.58097 18.9871 6.15326 18.75 6.75 18.75C7.34674 18.75 7.91903 18.9871 8.34099 19.409C8.58054 19.6486 8.7605 19.9366 8.87132 20.25H15.1287C15.2395 19.9366 15.4195 19.6486 15.659 19.409C16.081 18.9871 16.6533 18.75 17.25 18.75C17.8467 18.75 18.419 18.9871 18.841 19.409C19.0805 19.6486 19.2605 19.9366 19.3713 20.25H21.75C21.9489 20.25 22.1397 20.171 22.2803 20.0303C22.421 19.8897 22.5 19.6989 22.5 19.5V4.5C22.5 4.30109 22.421 4.11032 22.2803 3.96967C22.1397 3.82902 21.9489 3.75 21.75 3.75H2.25Z"
      />
    </styled.Svg>
  );
}

export default styled(IdCardIcon);
