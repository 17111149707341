import React from 'react';
import { styled } from '../../Theme';
import { StyledProps } from '@glitz/react';

function CalendarIcon({ compose }: StyledProps) {
  return (
    <styled.Svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      css={compose()}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 12.75C6 12.3358 6.33579 12 6.75 12H7.5C7.89783 12 8.27936 12.158 8.56066 12.4393C8.84196 12.7206 9 13.1022 9 13.5V18.75C9 19.1642 8.66421 19.5 8.25 19.5C7.83579 19.5 7.5 19.1642 7.5 18.75V13.5H6.75C6.33579 13.5 6 13.1642 6 12.75Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 18.75C6 18.3358 6.33579 18 6.75 18H9.75C10.1642 18 10.5 18.3358 10.5 18.75C10.5 19.1642 10.1642 19.5 9.75 19.5H6.75C6.33579 19.5 6 19.1642 6 18.75Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.082 12.75C13.082 12.3358 13.4178 12 13.832 12H15.7535C15.9953 12.0011 16.2333 12.0606 16.4471 12.1735C16.661 12.2864 16.8445 12.4492 16.9818 12.6483C17.1192 12.8473 17.2065 13.0765 17.2362 13.3165C17.2659 13.5565 17.2372 13.8001 17.1525 14.0267L15.2823 19.0134C15.1368 19.4012 14.7045 19.5977 14.3167 19.4522C13.9288 19.3068 13.7323 18.8745 13.8778 18.4866L15.7475 13.5013L15.7471 13.5001L13.832 13.5C13.4178 13.5 13.082 13.1642 13.082 12.75Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.25 4.5C1.83579 4.5 1.5 4.83579 1.5 5.25V21.75C1.5 22.1642 1.83579 22.5 2.25 22.5H21.75C22.1642 22.5 22.5 22.1642 22.5 21.75V5.25C22.5 4.83579 22.1642 4.5 21.75 4.5H2.25ZM0 5.25C0 4.00736 1.00736 3 2.25 3H21.75C22.9926 3 24 4.00736 24 5.25V21.75C24 22.9926 22.9926 24 21.75 24H2.25C1.00736 24 0 22.9926 0 21.75V5.25Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 9.75C0 9.33579 0.335786 9 0.75 9H23.25C23.6642 9 24 9.33579 24 9.75C24 10.1642 23.6642 10.5 23.25 10.5H0.75C0.335786 10.5 0 10.1642 0 9.75Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.75 0C7.16421 0 7.5 0.335786 7.5 0.75V6C7.5 6.41421 7.16421 6.75 6.75 6.75C6.33579 6.75 6 6.41421 6 6V0.75C6 0.335786 6.33579 0 6.75 0Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.25 0C17.6642 0 18 0.335786 18 0.75V6C18 6.41421 17.6642 6.75 17.25 6.75C16.8358 6.75 16.5 6.41421 16.5 6V0.75C16.5 0.335786 16.8358 0 17.25 0Z"
      />
    </styled.Svg>
  );
}

export default styled(CalendarIcon);
