import React, { useState, useRef, useEffect } from 'react';
import { styled, theme } from '../Theme';
import { media, pseudo } from '@glitz/core';
import useCurrentPage from '../Shared/Hooks/useCurrentPage';
import CheckoutPageModel from './Models/CheckoutPageModel.interface';
import Breadcrumb from '../Shared/Breadcrumb/Breadcrumb';
import { mediaQuery } from '../Theme/mediaQueries';
import { GetCart } from '../Cart/Cart';
import ProductListing from './ProductListing';
import CustomerInformation from '../../Features/CustomerInformation/CustomerInformation';
import CalendarIcon from '../Shared/Icons/CalendarIcon';
import ShipmentIcon from '../Shared/Icons/ShipmentIcon';
import { LoadingCircle } from '../Shared/Icons';
import { useKexNavigate } from '../Shared/KexRouter/KexRouter';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { addDays } from 'date-fns';

import { useAppSettingsData } from '../Shared/AppSettingsProvider/AppSettingsProvider';
import CtaButton from '../Shared/CtaButton/CtaButton';
import KexInput from '../Shared/Input/KexInput';
import { KexInputValidation } from '../Shared/Input/KexInputValidation';
import { PlaceOrder } from './Checkout';
import { useUserStateData } from '../Shared/UserContextProvider/UserContextProvider';
import FormSubmissionB2B from './Models/FormSubmissionB2B.interface';
import CustomerInformationModel from '../MyPagesPage/Models/CustomerInformationModel.interface';
import { GetMyInformation } from '../CustomerInformation/GetCustomerInformation';
import CustomerInformationLinks from '../CustomerInformation/CustomerInformation.interface';
import ShipmentInformationModel from './Models/ShipmentInformationModel.interface';
import { GetShippingInformation } from './GetShippingInformation';

type PropTypes = {
  initData: CustomerInformationLinks;
};

function CheckoutPage({ initData }: PropTypes) {
  const {
    translations: {
      'checkoutPage/delivery/title': deliveryTitleLabel,
      'checkoutPage/delivery/asap': asapLabel,
      'checkoutPage/delivery/laterDelivery': laterDeliveryLabel,
      'checkoutPage/delivery/datePickerHeading': datePickerHeadingLabel,
      'checkoutPage/delivery/deliveryInformation': deliveryInformationLabel,
      'checkoutPage/delivery/datePickerSelect': selectLabel,
      'checkoutPage/order/yourOrder': yourOrderLabel,
      'checkoutPage/order/message': messageLabel,
      'checkoutPage/order/messagePlaceholder': messagePlaceholderLabel,
      'checkoutPage/order/toPay': toPayLabel,
      'checkoutPage/order/sendOrder': sendOrderLabel,
      'checkoutPage/order/freightCost': freightCostLabel,
      'order/myOrders/totalPrice': totalLabel,
      'checkoutPage/shippingCostLabel': shippingInformationLabel,
    },
    languageRoute,
    requestToken,
    staticPages: { orderConfirmationPage },
  } = useAppSettingsData();

  const kexNavigate = useKexNavigate();
  const { pageHeading, breadcrumb, channelId } = useCurrentPage<
    CheckoutPageModel
  >();
  const { cart, isLoading } = GetCart();
  const [date, setDate] = useState(addDays(new Date(), 0));
  const [showDatePicker, setShowDatePicker] = useState<boolean>(false);
  const [deliveryInformationText, setDeliveryInformationText] = useState<
    string
  >(asapLabel);
  const { fullName, organizationId, customerId } = useUserStateData();
  const [messageText, setMessageText] = useState<string>('');
  const datePickerRef = useRef<HTMLDivElement>(null);
  const [submitErrorMsg, setSubmitErrorMsg] = useState<string>('');
  const [submitIsLoading, setSubmitIsLoading] = useState<boolean>(false);

  const month = date.toLocaleString('default', { month: 'long' });
  const day = date.getDate();

  const customerInfoUrl = `/api/${languageRoute}/customer/GetMyInformation?customerId=${customerId}`;
  const [customerInformation, setCustomerInformation] = useState<
    CustomerInformationModel
  >();

  const shippingInfo = `/api/${languageRoute}/customer/GetShippingInformation?organizationId=${organizationId}`;
  const [shippingInformation, setShippingInformation] = useState<
    ShipmentInformationModel
  >();

  useEffect(() => {
    organizationId &&
      GetShippingInformation(channelId, requestToken, shippingInfo).then(
        data => data && setShippingInformation(data)
      );
  }, [channelId]);

  useEffect(() => {
    customerId &&
      GetMyInformation(channelId, requestToken, customerInfoUrl).then(
        data => data && setCustomerInformation(data)
      );
  }, [channelId]);

  const onLaterDeliveryClick = () => {
    if (!showDatePicker) {
      setShowDatePicker(true);
      setTimeout(() => {
        datePickerRef?.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'center',
        });
      }, 100);
    } else {
      setShowDatePicker(false);
    }
  };

  const onDateChange = () => {
    let deliveryText = '';
    deliveryText = `${deliveryInformationLabel} ${day} ${month}`;
    setDeliveryInformationText(deliveryText);
    setShowDatePicker(false);
  };

  const isWeekday = (date: Date) => {
    const day = date.getDay();
    return day !== 0 && day !== 6;
  };

  const orderSubmitData: FormSubmissionB2B = {
    company: fullName,
    customerId: customerId,
    organizationId: organizationId,
    name: fullName,
    billingAddress:
      customerInformation &&
      customerInformation.invoiceAddress &&
      customerInformation.invoiceAddress.street,
    billingCity:
      customerInformation &&
      customerInformation.invoiceAddress &&
      customerInformation.invoiceAddress.city,
    billingCountry:
      customerInformation &&
      customerInformation.invoiceAddress &&
      customerInformation.invoiceAddress.country,
    billingPostalCode:
      customerInformation &&
      customerInformation.invoiceAddress &&
      customerInformation.invoiceAddress.postalCode,
    shippingAddress:
      customerInformation &&
      customerInformation.deliveryAddress &&
      customerInformation.deliveryAddress.street,
    shippingCity:
      customerInformation &&
      customerInformation.deliveryAddress &&
      customerInformation.deliveryAddress.city,
    shippingCountry:
      customerInformation &&
      customerInformation.deliveryAddress &&
      customerInformation.deliveryAddress.country,
    shippingPostalCode:
      customerInformation &&
      customerInformation.deliveryAddress &&
      customerInformation.deliveryAddress.postalCode,
    // selectedDeliveryMethod: '',
    // selectedPaymentMethod: '',
    requestedDeliveryDate: date,
    orderNote: messageText,
    // salesPersonId: '',
  };

  async function submitOrder() {
    const data = await PlaceOrder(
      orderSubmitData,
      channelId,
      languageRoute,
      setSubmitIsLoading
    );
    if (data.success) {
      let url = `${orderConfirmationPage}?orderId=${data.orderId}`;
      kexNavigate(url);
    } else {
      setSubmitErrorMsg(data.message);
    }
  }

  return (
    <main>
      {!!breadcrumb.length && <Breadcrumb breadcrumb={breadcrumb} />}
      <PageWrapper>
        <Heading>{pageHeading} </Heading>
        {cart && cart.lineItems && !!cart.lineItems.length && (
          <ProductListing />
        )}
        <CustomerInformation
          onCheckoutPage={true}
          customerInformationData={customerInformation}
        />
        <SectionWrapper>
          <TopSection>
            <TitleContainer>
              <Title>
                <StyledCalendarIcon /> {deliveryTitleLabel}{' '}
              </Title>
              <LaterDeliveryButton
                onClick={() => onLaterDeliveryClick()}
                data-isselected={showDatePicker}
              >
                {laterDeliveryLabel}
              </LaterDeliveryButton>
            </TitleContainer>
            <DeliveryInformation>{deliveryInformationText}</DeliveryInformation>
          </TopSection>
          {showDatePicker && (
            <DatePickerSection ref={datePickerRef}>
              <DatePickerHeading>{datePickerHeadingLabel}</DatePickerHeading>
              <DataPickerContainer>
                <div>
                  <DatePicker
                    showFullMonthYearPicker={true}
                    selected={date}
                    onChange={(date: Date) => setDate(date)}
                    inline
                    shouldCloseOnSelect={false}
                    minDate={addDays(new Date(), 0)}
                    filterDate={isWeekday}
                  />
                </div>
                <DatePickerButton onClick={() => onDateChange()}>
                  {selectLabel}
                </DatePickerButton>
              </DataPickerContainer>
            </DatePickerSection>
          )}
        </SectionWrapper>
        <SectionWrapper>
          <OrderTitleWrapper>
            <Title>
              <StyledShipmentIcon /> {yourOrderLabel}{' '}
            </Title>
          </OrderTitleWrapper>
          <OrderContentWrapper>
            <MessageText>{messageLabel}</MessageText>
            <KexInputValidation>
              <KexInput
                onChange={setMessageText}
                title={messagePlaceholderLabel}
                showLabel={false}
              />
            </KexInputValidation>
            <OrderSummaryContainer>
              <PriceSummaryContainer>
                <PriceSummaryListItem>
                  <span>{totalLabel}:</span>
                  <span>{cart && cart.totalPriceForItemsExclVAT}</span>
                </PriceSummaryListItem>
                <PriceSummaryListItem>
                  <span>{freightCostLabel}:</span>
                  <span>{shippingInformation?.shippingCost}</span>
                </PriceSummaryListItem>
                <ShippingInfo>
                  <span>{shippingInformationLabel}</span>
                  <span>{shippingInformation?.orderValueLimit}</span>
                </ShippingInfo>
              </PriceSummaryContainer>
              <ToPayWrapper>
                <span>{toPayLabel}:</span>
                <span>{cart && cart.totalPrice}</span>
              </ToPayWrapper>
              <SubmitOrderButton
                onClick={() => submitOrder()}
                disabled={cart && cart.lineItems && !!!cart.lineItems.length}
              >
                {submitIsLoading ? (
                  <StyledLoadingCircle isLoading={submitIsLoading} />
                ) : (
                  <>{sendOrderLabel}</>
                )}
              </SubmitOrderButton>
              {submitErrorMsg && <ErrorMessage>{submitErrorMsg}</ErrorMessage>}
            </OrderSummaryContainer>
          </OrderContentWrapper>
        </SectionWrapper>
      </PageWrapper>
    </main>
  );
}

export default CheckoutPage;

const DatePickerSection = styled.div({
  paddingTop: theme.spacing(8),
  paddingBottom: theme.spacing(13),
  borderTopColor: theme.veryLightGray,
  borderTopStyle: 'solid',
  borderTopWidth: '1px',
  ...media(mediaQuery.mediaMinLarge, {
    paddingTop: theme.spacing(15),
  }),
});

const ErrorMessage = styled.div({
  color: 'red',
  marginTop: '10px',
});

const StyledLoadingCircle = styled(LoadingCircle, {
  color: theme.white,
  height: theme.gamma,
  margin: { xy: 'auto' },
});

const DataPickerContainer = styled.div({
  display: 'flex',
  maxWidth: '400px',
  margin: { x: 'auto' },
  flexDirection: 'column',
  alignItems: 'center',
  boxShadow: '0px 5px 50px rgba(0, 0, 0, 0.03)',
  borderRadius: '10px',
  border: { xy: { width: '1px', style: 'solid', color: theme.veryLightGray } },
  paddingTop: theme.spacing(7),
  paddingBottom: theme.spacing(9),
  ...media(mediaQuery.mediaMaxLarge, {}),
});

const OrderContentWrapper = styled.div({
  maxWidth: '500px',
  margin: { x: 'auto' },
});

const StyledCalendarIcon = styled(CalendarIcon, {
  fill: theme.black,
  marginRight: theme.spacing(3),
});

const StyledShipmentIcon = styled(ShipmentIcon, {
  fill: theme.black,
  marginRight: theme.spacing(3),
});

const OrderTitleWrapper = styled.div({
  marginBottom: theme.spacing(8),
});

const PageWrapper = styled.div({
  paddingTop: theme.spacing(10),
  paddingBottom: theme.spacing(20),
  padding: {
    x: '7.5px',
  },
  ...media(mediaQuery.mediaMinLarge, {
    maxWidth: '900px',
    margin: { x: 'auto' },
    padding: {
      x: 0,
    },
  }),
});

const LaterDeliveryButton = styled.button({
  fontSize: theme.gamma,
  color: theme.linkColor,
  textDecoration: 'underline',
  ...pseudo([':nth-child(n)[data-isselected="true"]'], {
    color: theme.purple,
  }),
});

const MessageText = styled.div({
  color: theme.black,
  font: { size: theme.delta, weight: theme.fontWeight.bold },
  marginBottom: theme.spacing(3),
});

const DatePickerButton = styled(CtaButton, {
  margin: { x: 'auto' },
  marginTop: theme.spacing(4),
  padding: { x: theme.spacing(10) },
});

const SubmitOrderButton = styled(CtaButton, {
  width: '100%',
  fontSize: '15px',
  lineHeight: '18px',
  padding: { y: '21px' },
});

const TopSection = styled.div({ paddingBottom: theme.spacing(6) });

const DatePickerHeading = styled.div({
  color: theme.black,
  font: { size: theme.delta, weight: theme.fontWeight.bold },
  lineHeight: '21.6px',
  marginBottom: theme.spacing(5),
  padding: { x: theme.spacing(7) },
  textAlign: 'center',
  ...media(theme.mediaQuery.mediaMinLarge, {
    marginBottom: theme.spacing(8),
  }),
});

const Heading = styled.h1({
  font: { size: theme.psi, weight: theme.fontWeight.bold },
  color: theme.black,
  marginBottom: theme.spacing(5),
  ...media(theme.mediaQuery.mediaMinLarge, {
    lineHeight: '40px',
    fontSize: '32px',
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(10),
  }),
});

const TitleContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontSize: '22px',
  color: theme.black,
});

const Title = styled.h2({
  display: 'flex',
  alignItems: 'center',
  fontSize: '22px',
  color: theme.black,
});

const DeliveryInformation = styled.span({
  lineHeight: '25.6px',
  fontSize: theme.gamma,
  color: theme.black,
  marginLeft: theme.spacing(9),
});

const SectionWrapper = styled.div({
  padding: { x: theme.spacing(4), top: theme.spacing(6) },
  backgroundColor: theme.white,
  borderRadius: '10px',
  marginBottom: theme.spacing(9),
  ...media(theme.mediaQuery.mediaMinLarge, {
    padding: {
      x: theme.spacing(11),
      top: theme.spacing(8),
    },
  }),
  ...pseudo(':last-of-type', {
    marginBottom: 0,
  }),
});

const OrderSummaryContainer = styled.div({
  marginTop: theme.spacing(5),
  paddingBottom: theme.spacing(17),
  ...media(theme.mediaQuery.mediaMinLarge, {
    marginTop: theme.spacing(9),
    paddingBottom: theme.spacing(20),
  }),
});

const PriceSummaryContainer = styled.ul({
  borderTopColor: theme.veryLightGray,
  borderTopStyle: 'solid',
  borderTopWidth: '1px',
  borderBottomColor: theme.veryLightGray,
  borderBottomStyle: 'solid',
  borderBottomWidth: '1px',
  padding: { y: theme.spacing(6) },
  marginBottom: theme.spacing(6),
});

const PriceSummaryListItem = styled.li({
  color: theme.black,
  display: 'flex',
  justifyContent: 'space-between',
  font: { size: theme.gamma },
  lineHeight: '25.6px',
  marginBottom: theme.spacing(3),
  ...pseudo(':last-of-type', {
    marginBottom: 0,
  }),
});

const ToPayWrapper = styled.div({
  font: { size: theme.epsilon, weight: theme.fontWeight.bold },
  lineHeight: '24px',
  color: theme.black,
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(11),
});

const ShippingInfo = styled.div({
  color: theme.black,
  display: 'flex',
  justifyContent: 'space-between',
  font: { size: theme.gamma },
  lineHeight: '25.6px',
  marginBottom: theme.spacing(3),
  ...pseudo(':last-of-type', {
    marginBottom: 0,
  }),
});
