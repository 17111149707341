import React from 'react';
import { styled } from '../../Theme';
import { StyledProps } from '@glitz/react';

function ShipmentIcon({ compose }: StyledProps) {
  return (
    <styled.Svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      css={compose()}
    >
      <g clipPath="url(#clip0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.7215 0.0536425C11.9003 -0.0178808 12.0997 -0.0178808 12.2785 0.0536425L23.5285 4.55364C23.8133 4.66754 24 4.94332 24 5.25C24 5.55668 23.8133 5.83246 23.5285 5.94636L12.2785 10.4464C12.0997 10.5179 11.9003 10.5179 11.7215 10.4464L0.471457 5.94636C0.186713 5.83246 0 5.55668 0 5.25C0 4.94332 0.186713 4.66754 0.471457 4.55364L11.7215 0.0536425ZM2.76944 5.25L12 8.94222L21.2306 5.25L12 1.55777L2.76944 5.25Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.329528 4.62895C0.535608 4.48943 0.797475 4.46122 1.02854 4.55365L12.2785 9.05365C12.5633 9.16754 12.75 9.44333 12.75 9.75V23.25C12.75 23.4989 12.6266 23.7315 12.4205 23.8711C12.2144 24.0106 11.9525 24.0388 11.7215 23.9464L0.471457 19.4464C0.186713 19.3325 0 19.0567 0 18.75V5.25C0 5.00114 0.123449 4.76848 0.329528 4.62895ZM1.5 6.35778V18.2422L11.25 22.1422V10.2578L1.5 6.35778Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.6705 4.62895C23.8766 4.76848 24 5.00114 24 5.25V18.75C24 19.0567 23.8133 19.3325 23.5285 19.4464L12.2785 23.9464C12.0475 24.0388 11.7856 24.0106 11.5795 23.8711C11.3734 23.7315 11.25 23.4989 11.25 23.25V9.75C11.25 9.44333 11.4367 9.16754 11.7215 9.05365L22.9715 4.55365C23.2025 4.46122 23.4644 4.48943 23.6705 4.62895ZM12.75 10.2578V22.1422L22.5 18.2422V6.35778L12.75 10.2578Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.24134 2.49607C6.39518 2.11148 6.83165 1.92442 7.21624 2.07826L18.4662 6.57826C18.8508 6.73209 19.0379 7.16857 18.8841 7.55316C18.7302 7.93774 18.2937 8.12481 17.9092 7.97097L6.65916 3.47097C6.27457 3.31714 6.08751 2.88066 6.24134 2.49607Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.3216 16.2217C21.4754 16.6062 21.2883 17.0427 20.9037 17.1966L19.0287 17.9466C18.6442 18.1004 18.2077 17.9133 18.0538 17.5287C17.9 17.1442 18.0871 16.7077 18.4717 16.5538L20.3467 15.8038C20.7312 15.65 21.1677 15.8371 21.3216 16.2217Z"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </styled.Svg>
  );
}

export default styled(ShipmentIcon);
