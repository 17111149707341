import Fetcher from '../Shared/Common/Fetcher';
import CustomerInformationModel from '../MyPagesPage/Models/CustomerInformationModel.interface';

let abortController: AbortController = new AbortController();

export async function GetMyInformation(
  channelId: string,
  requestToken: string,
  url: string
) {
  abortController.abort();
  abortController = new AbortController();
  const signal = abortController.signal;

  const litiumContext = JSON.stringify({
    channelSystemId: channelId,
  });
  return Fetcher<any, CustomerInformationModel>(
    url,
    signal,
    (data, resolve) => {
      resolve(data);
    },
    litiumContext,
    requestToken
  );
}
