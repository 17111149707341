import React, { useState } from 'react';
import { styled, theme } from '../Theme';
import { media, pseudo } from '@glitz/core';
import useCurrentPage from '../Shared/Hooks/useCurrentPage';
import CheckoutPageModel from './Models/CheckoutPageModel.interface';
import Breadcrumb from '../Shared/Breadcrumb/Breadcrumb';
import { mediaQuery } from '../Theme/mediaQueries';
import { GetCart, SetQuantity, RemoveFromCart } from '../Cart/Cart';
import ProductListing from './ProductListing';
import VariationModel from '../KexVariation/Models/VariationModel.interface';
import PlusToggleIcon from '../Shared/Icons/PlusToggleIcon';
import MinusToggleIcon from '../Shared/Icons/MinusToggleIcon';
import TrashIcon from '../Shared/Icons/TrashIcon';
import { useAppSettingsData } from '../Shared/AppSettingsProvider/AppSettingsProvider';
import KexCart from '../Cart/Models/KexCart.interface';

type PropTypes = {
  lineItem: VariationModel;
  cart: KexCart;
};

function CheckoutMobileProductCard({ lineItem, cart }: PropTypes) {
  const {
    translations: {
      'common/price': priceLabel,
      'common/quantity': quantityLabel,
      'common/total': totalLabel,
      'common/currency': currencyLabel,
    },
    languageRoute,
  } = useAppSettingsData();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [inputQuantity, setInputQuantity] = useState<string>(
    lineItem.quantity.toString()
  );

  const onQuantityMinus = () => {
    setInputQuantity((Number(inputQuantity) - 1).toString());
    SetQuantity(
      lineItem.articleNumber,
      '',
      Number(Number(inputQuantity) - 1),
      languageRoute
    );
  };

  const onQuantityPlus = () => {
    setInputQuantity((Number(inputQuantity) + 1).toString());
    SetQuantity(
      lineItem.articleNumber,
      '',
      Number(Number(inputQuantity) + 1),
      languageRoute
    );
  };

  const onRemoveProduct = () => {
    RemoveFromCart(lineItem.articleNumber, languageRoute);
  };

  return (
    <Root>
      <TopSection>
        <a href={lineItem.to}>
          <Image src={lineItem.image && lineItem.image.src} />
        </a>
        <a href={lineItem.to}>
          <TextArea>
            <CategoryText>Category</CategoryText>
            <ProductName>{lineItem.name}</ProductName>
          </TextArea>
        </a>
        <TrashIconWrapper onClick={() => onRemoveProduct()}>
          <StyledTrashIcon />
        </TrashIconWrapper>
      </TopSection>
      <BottomSection>
        <ListItem>
          <ListItemProp>{priceLabel}:</ListItemProp>
          <ListItemValue>{lineItem.price}</ListItemValue>
        </ListItem>
        <ListItem data-whitebackground={true}>
          <ListItemProp>{quantityLabel}:</ListItemProp>
          {!isLoading ? (
            <QuantityWrapper>
              <MinusButton
                disabled={inputQuantity === '1'}
                onClick={() => onQuantityMinus()}
              >
                <StyledMinusIcon />
              </MinusButton>
              <QuantityInput
                type="number"
                value={inputQuantity}
                onChange={e => setInputQuantity(e.target.value)}
              />
              <button onClick={() => onQuantityPlus()}>
                <StyledPlusIcon />
              </button>
            </QuantityWrapper>
          ) : (
            <div>hej</div>
          )}
        </ListItem>
        <ListItem>
          <ListItemProp>{totalLabel}:</ListItemProp>
          <ListItemValue>{lineItem.totalPrice}</ListItemValue>
        </ListItem>
      </BottomSection>
    </Root>
  );
}

const Root = styled.div({
  paddingTop: theme.spacing(4),
  marginBottom: theme.spacing(5),
  borderTopColor: theme.veryLightGray,
  borderTopStyle: 'solid',
  borderTopWidth: '1px',
});

const QuantityWrapper = styled.div({ display: 'flex' });

const QuantityInput = styled.input({
  maxWidth: '40px',
  textAlign: 'center',
  MozAppearance: 'textfield', // hide number arrows Firefox
  ...pseudo(['::-webkit-outer-spin-button', '::-webkit-inner-spin-button'], {
    WebkitAppearance: 'none', // hide number arrows Chrome, Safari, Edge, Opera
    margin: { xy: 0 },
  }),
});

const TopSection = styled.div({
  display: 'flex',
  alignItems: 'center',
  padding: { x: theme.spacing(1) },
  marginBottom: theme.spacing(3),
});

const MinusButton = styled.button({
  ':disabled': {
    cursor: 'default',
    opacity: 0.6,
  },
});

const StyledPlusIcon = styled(PlusToggleIcon, {
  height: '18px',
  width: '18px',
  fill: theme.black,
});

const StyledMinusIcon = styled(MinusToggleIcon, {
  height: '18px',
  width: '18px',
  fill: theme.black,
});

const BottomSection = styled.ul({});

const ListItem = styled.li({
  backgroundColor: theme.primaryBackground,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  lineHeight: '21.6px',
  padding: { x: theme.spacing(6), y: theme.spacing(3) },
  ...pseudo([':nth-child(n)[data-whitebackground="true"]'], {
    backgroundColor: theme.white,
  }),
});

const ListItemProp = styled.span({ fontSize: theme.beta });

const ListItemValue = styled.span({
  font: { size: theme.delta, weight: theme.fontWeight.bold },
  color: theme.black,
});

const Image = styled.img({
  height: '80px',
  width: '60px',
  objectFit: 'contain',
});

const TextArea = styled.div({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: theme.spacing(5),
});

const CategoryText = styled.span({
  fontSize: theme.alpha,
  color: theme.middleGray,
  textTransform: 'uppercase',
  marginBottom: theme.spacing(1),
});

const ProductName = styled.span({
  font: { size: theme.delta, weight: theme.fontWeight.bold },
  color: theme.black,
  lineHeight: '21.6px',
});

const TrashIconWrapper = styled.div({ marginLeft: 'auto' });

const StyledTrashIcon = styled(TrashIcon, {
  stroke: theme.black,
});

export default CheckoutMobileProductCard;
