import React from 'react';
import { styled } from '../../Theme';
import { StyledProps } from '@glitz/react';

function ShoppingBasketIconBold({ compose }: StyledProps) {
  return (
    <styled.Svg
      css={compose()}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.90093 10.5004C2.90957 10.5001 2.91821 10.5 2.92685 10.5H21.0749C21.0835 10.5 21.0921 10.5001 21.1008 10.5004C21.4363 10.5121 21.7649 10.5991 22.0621 10.7552C22.3594 10.9113 22.6176 11.1324 22.8177 11.402C23.0177 11.6716 23.1544 11.9828 23.2176 12.3126C23.2801 12.6387 23.2691 12.9747 23.1855 13.296L21.0511 22.2809C20.9452 22.7603 20.6815 23.1904 20.302 23.5021C19.9204 23.8155 19.4442 23.9909 18.9505 23.9999L18.9369 23.25V24H5.05122C4.55751 23.991 4.08131 23.8155 3.69974 23.5021C3.32026 23.1904 3.05651 22.7603 2.95064 22.281L0.81621 13.296C0.732559 12.9747 0.721564 12.6387 0.784083 12.3126C0.847284 11.9828 0.983989 11.6716 1.18403 11.402C1.38408 11.1324 1.64232 10.9113 1.93956 10.7552C2.23681 10.5991 2.56541 10.5121 2.90093 10.5004ZM18.9288 22.5C19.0827 22.496 19.2309 22.4407 19.3499 22.343C19.4703 22.244 19.5539 22.1073 19.5869 21.955L19.5902 21.9407L21.7282 12.9407L21.733 12.9215C21.7613 12.8149 21.7652 12.7033 21.7444 12.5949C21.7237 12.4866 21.6788 12.3843 21.613 12.2958C21.5473 12.2072 21.4625 12.1345 21.3648 12.0833C21.2703 12.0336 21.1661 12.0052 21.0596 12H2.94208C2.83555 12.0052 2.73141 12.0336 2.63691 12.0833C2.53925 12.1345 2.4544 12.2072 2.38867 12.2958C2.32294 12.3843 2.27803 12.4866 2.25726 12.5949C2.2365 12.7033 2.24042 12.8149 2.26873 12.9215L2.27355 12.9407L4.41155 21.9407L4.4148 21.955C4.44785 22.1073 4.53137 22.244 4.65183 22.343C4.77085 22.4407 4.91904 22.496 5.0729 22.5H18.9288Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 13.5C7.91421 13.5 8.25 13.8358 8.25 14.25V20.25C8.25 20.6642 7.91421 21 7.5 21C7.08579 21 6.75 20.6642 6.75 20.25V14.25C6.75 13.8358 7.08579 13.5 7.5 13.5Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 13.5C12.4142 13.5 12.75 13.8358 12.75 14.25V20.25C12.75 20.6642 12.4142 21 12 21C11.5858 21 11.25 20.6642 11.25 20.25V14.25C11.25 13.8358 11.5858 13.5 12 13.5Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5 13.5C16.9142 13.5 17.25 13.8358 17.25 14.25V20.25C17.25 20.6642 16.9142 21 16.5 21C16.0858 21 15.75 20.6642 15.75 20.25V14.25C15.75 13.8358 16.0858 13.5 16.5 13.5Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.71383 1.77806C9.83976 2.17267 9.62194 2.59464 9.22733 2.72056C7.9874 3.11624 6.8862 3.85772 6.05325 4.85781C5.22029 5.8579 4.69019 7.07503 4.52529 8.36608C4.47281 8.77696 4.09718 9.0675 3.68631 9.01502C3.27543 8.96254 2.9849 8.58691 3.03738 8.17604C3.23847 6.60165 3.8849 5.11741 4.90066 3.89784C5.91642 2.67827 7.25927 1.77407 8.77133 1.29156C9.16594 1.16564 9.58791 1.38345 9.71383 1.77806Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2856 1.77824C14.4115 1.3836 14.8334 1.16568 15.228 1.2915C16.7471 1.77581 18.0952 2.68594 19.1122 3.91378C20.1293 5.14163 20.7726 6.63559 20.9657 8.21823C21.0158 8.6294 20.7232 9.00338 20.312 9.05354C19.9009 9.1037 19.5269 8.81106 19.4767 8.39989C19.3184 7.10228 18.791 5.87737 17.9571 4.87065C17.1232 3.86393 16.0178 3.11771 14.7724 2.72062C14.3777 2.5948 14.1598 2.17288 14.2856 1.77824Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.125 1.5C9.91789 1.5 9.75 1.66789 9.75 1.875V2.625C9.75 2.83211 9.91789 3 10.125 3H13.875C14.0821 3 14.25 2.83211 14.25 2.625V1.875C14.25 1.66789 14.0821 1.5 13.875 1.5H10.125ZM8.25 1.875C8.25 0.839466 9.08947 0 10.125 0H13.875C14.9105 0 15.75 0.839466 15.75 1.875V2.625C15.75 3.66053 14.9105 4.5 13.875 4.5H10.125C9.08947 4.5 8.25 3.66053 8.25 2.625V1.875Z"
      />
    </styled.Svg>
  );
}

export default styled(ShoppingBasketIconBold);
