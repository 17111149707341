import FormSubmissionB2B from './Models/FormSubmissionB2B.interface';

let checkoutUrl = '';
let litiumContext = '';

export async function PlaceOrder(
  data: FormSubmissionB2B,
  channelId: string,
  languageRoute: string,
  setSubmitIsLoading?: (value: boolean) => void
) {
  setSubmitIsLoading && setSubmitIsLoading(true);
  checkoutUrl = `/api/${languageRoute}/checkout/`;
  litiumContext = JSON.stringify({
    channelSystemId: channelId,
  });
  const res = await fetch(`${checkoutUrl}PlaceOrder`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'litium-request-context': litiumContext,
    },
    body: JSON.stringify(data),
  });
  if (res.ok) {
    const data = await res.json();
    setSubmitIsLoading && setSubmitIsLoading(false);
    return data;
  }
}
