import React, { useState } from 'react';
import VariationModel from '../KexVariation/Models/VariationModel.interface';
import KexCart from '../Cart/Models/KexCart.interface';
import { styled } from '@glitz/react';
import { theme } from '../Theme';
import TrashIcon from '../Shared/Icons/TrashIcon';
import PlusToggleIcon from '../Shared/Icons/PlusToggleIcon';
import MinusToggleIcon from '../Shared/Icons/MinusToggleIcon';
import { GetCart, SetQuantity, RemoveFromCart } from '../Cart/Cart';
import { pseudo } from '@glitz/core';
import Dots from '../Shared/Button/Dots';
import LoadingDots from '../Shared/Loading/LoadingDots/LoadingDots';
import { useAppSettingsData } from '../Shared/AppSettingsProvider/AppSettingsProvider';

type PropTypes = {
  lineItem: VariationModel;
  cart: KexCart;
};

function CheckoutProductCard({ lineItem, cart }: PropTypes) {
  const { languageRoute } = useAppSettingsData();

  const [inputQuantity, setInputQuantity] = useState<string>(
    lineItem.quantity.toString()
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onQuantityMinus = () => {
    setInputQuantity((Number(inputQuantity) - 1).toString());
    SetQuantity(
      lineItem.articleNumber,
      '',
      Number(Number(inputQuantity) - 1),
      languageRoute,
      setIsLoading
    );
  };

  const onQuantityPlus = () => {
    setInputQuantity((Number(inputQuantity) + 1).toString());
    SetQuantity(
      lineItem.articleNumber,
      '',
      Number(Number(inputQuantity) + 1),
      languageRoute,
      setIsLoading
    );
  };

  const onRemoveProduct = () => {
    RemoveFromCart(lineItem.articleNumber, languageRoute);
  };

  const onInputBlur = (numb: string) => {
    if (Number(numb) < 1) {
      setInputQuantity('1');
      SetQuantity(lineItem.articleNumber, '', 1, languageRoute, setIsLoading);
    } else {
      setInputQuantity(numb);
      SetQuantity(
        lineItem.articleNumber,
        '',
        Number(numb),
        languageRoute,
        setIsLoading
      );
    }
  };

  return (
    <Root>
      <ImageCell>
        <ImageAreaWrapper>
          <a href={lineItem.to}>
            <Image src={lineItem.image && lineItem.image.src} />
          </a>
          <a href={lineItem.to}>
            <TextArea>
              <CategoryText>Category</CategoryText>
              <ProductName>{lineItem.name}</ProductName>
            </TextArea>
          </a>
        </ImageAreaWrapper>
      </ImageCell>
      <PriceCell>{lineItem.price}</PriceCell>
      <PriceCell>
        <QuantityWrapper>
          <>
            <MinusButton
              disabled={inputQuantity === '1'}
              onClick={
                () => onQuantityMinus()
                // setInputQuantity((Number(inputQuantity) - 1).toString())
              }
            >
              <StyledMinusIcon />
            </MinusButton>
            <QuantityInput
              type="number"
              value={inputQuantity}
              onChange={e => setInputQuantity(e.target.value)}
              onBlur={e => onInputBlur(e.target.value)}
            />
            <button onClick={() => onQuantityPlus()}>
              <StyledPlusIcon />
            </button>
          </>
          {/* // ) : (
          //   <DotsWrapper>
          //     <LoadingDots />
          //   </DotsWrapper>
          // )} */}
        </QuantityWrapper>
      </PriceCell>
      <PriceCell>{lineItem.totalPrice}</PriceCell>
      <PriceCell>
        <div onClick={() => onRemoveProduct()}>
          <StyledTrashIcon />
        </div>
      </PriceCell>
    </Root>
  );
}

const Root = styled.div({
  display: 'table-row',
  borderBottomColor: theme.veryLightGray,
  borderBottomStyle: 'solid',
  borderBottomWidth: '1px',
});

const ImageCell = styled.div({
  display: 'table-cell',
  width: '45%',
  padding: { y: theme.spacing(4) },
});

const ImageAreaWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
});

const DotsWrapper = styled.div({
  maxHeight: '40px',
  display: 'flex',
  justifyContent: 'center',
  padding: { y: '6px' },
  marginTop: '4px',
});

const PriceCell = styled.div({
  color: theme.black,
  font: { size: theme.delta, weight: theme.fontWeight.bold },
  display: 'table-cell',
  textAlign: 'center',
  verticalAlign: 'middle',
});

const Image = styled.img({
  height: '80px',
  width: '60px',
  objectFit: 'contain',
});

const QuantityWrapper = styled.div({
  backgroundColor: theme.primaryBackground,
  borderRadius: '100px',
  display: 'flex',
  justifyContent: 'center',
  padding: { y: '11px' },
});

const QuantityInput = styled.input({
  backgroundColor: theme.primaryBackground,
  maxWidth: '40px',
  textAlign: 'center',
  MozAppearance: 'textfield', // hide number arrows Firefox
  ...pseudo(['::-webkit-outer-spin-button', '::-webkit-inner-spin-button'], {
    WebkitAppearance: 'none', // hide number arrows Chrome, Safari, Edge, Opera
    margin: { xy: 0 },
  }),
});

const TextArea = styled.div({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: theme.spacing(6),
});

const CategoryText = styled.span({
  fontSize: theme.alpha,
  color: theme.middleGray,
  textTransform: 'uppercase',
  marginBottom: theme.spacing(1),
});

const ProductName = styled.span({
  font: { size: theme.delta, weight: theme.fontWeight.bold },
  color: theme.black,
  lineHeight: '25.6px',
});

const MinusButton = styled.button({
  ':disabled': {
    cursor: 'default',
    opacity: 0.6,
  },
});

const StyledPlusIcon = styled(PlusToggleIcon, {
  height: '18px',
  width: '18px',
  fill: theme.black,
});

const StyledMinusIcon = styled(MinusToggleIcon, {
  height: '18px',
  width: '18px',
  fill: theme.black,
});

const StyledTrashIcon = styled(TrashIcon, {
  stroke: theme.black,
  ':hover': {
    cursor: 'pointer',
  },
});

export default CheckoutProductCard;
